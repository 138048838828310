<template>
  <div>
    <div v-if="selectedParcels" class="tw-flex tw-flex-wrap tw-gap-1">
      <span
        v-for="(parcel, cadastralKey) in selectedParcels"
        :key="cadastralKey"
        class="label label-default tw-m-0"
        v-html="parcel"
      />
    </div>
    <div v-else>
      <span>Er zijn nog geen percelen geselecteerd voor dit dossier.</span>
    </div>
    <div id="map" class="tw-h-[58vh]"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import Map from 'ol/Map.js'
import View from 'ol/View.js'
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import { bbox as bboxStrategy } from 'ol/loadingstrategy'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js'
import VectorSource from 'ol/source/Vector.js'
import { Draw, Modify, Snap, Select } from 'ol/interaction.js'
import { click, pointerMove } from 'ol/events/condition.js'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style.js'
import XYZ from 'ol/source/XYZ'
import { fromLonLat, toLonLat } from 'ol/proj'

export default {
  name: 'EntityMap',
  props: ['longitude', 'latitude', 'parcelString'],
  data () {
    return {
      mode: 'LOCATION',
      rendered: false,
      map: null,
      cityLayer: null,
      source: null,
      propertyFeature: undefined,
      selectedParcels: {},

      defaultParcelStyle: new Style({
        stroke: new Stroke({
          color: 'rgb(0,85,255)',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.4)'
        })
      }),
      selectedParcelStyle: new Style({
        stroke: new Stroke({
          color: 'rgb(0,255,13)',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(74,127,62,0.77)'
        })
      })
    }
  },
  computed: {
    location () {
      return this.map.getView().getCenter()
    },
    zoom () {
      return this.map.getView().getZoom()
    }
  },
  methods: {
    extractCadastralKey (capakey, nis) {
      const key = capakey.substr(4, 20)
      const sectie = key.substr(1, 1)
      const perceel = key.substr(2, 20)
      return `${nis} sectie ${sectie} perceel ${perceel}`
    },
    parseParcelString () {
      if (this.parcelString.length === 0) {
        return
      }
      this.parcels = {}
      const keys = this.parcelString.split(',')
      keys.forEach(k => {
        if (k.length > 0) {
          const v = this.extractCadastralKey(k, 'FOO')
          Vue.set(this.selectedParcels, k, v)
        }
      })
    },
    getCoordinates () {
      const feature = this.locationSource.getFeatures()[0]
      let coords = feature.getGeometry().getCoordinates()
      coords = toLonLat(coords)
      return {
        lon: coords[0],
        lat: coords[1]
      }
    },
    getParcels () {
      return this.selectedParcels
    },
    clearInteractions () {
      this.map.removeInteraction(this.draw)
      this.map.removeInteraction(this.snap)
      this.map.removeInteraction(this.modify)
    },
    activateParcelSelection () {
      this.mode = 'PARCEL'
      if (this.map) {
        this.parseParcelString()
        // this.clearInteractions()

        this.selectPointerMove = new Select({
          condition: pointerMove,
          layers: [this.grbLayer]
        })

        this.selectClick = new Select({
          condition: click,
          layers: [this.grbLayer]
        })

        this.selectClick.on('select', (e) => {
          var selectedFeature = e.target.getFeatures().getArray()[0]
          const capakey = selectedFeature.getProperties().CAPAKEY
          const nis = selectedFeature.getProperties().NISCODE
          if (capakey in this.selectedParcels) {
            Vue.delete(this.selectedParcels, capakey)
          } else {
            Vue.set(this.selectedParcels, capakey, this.extractCadastralKey(capakey, nis))
          }

          e.target.getFeatures().clear() // clear selection
          this.grbLayer.changed() // Force layer redraw
        })

        this.map.addInteraction(this.selectPointerMove)
        this.map.addInteraction(this.selectClick)
      }
    },
    activateLocationSelection () {
      this.mode = 'LOCATION'
      if (this.map) {
        // this.clearInteractions()

        this.draw = new Draw({
          source: this.locationSource,
          type: 'Point'
        })

        this.snap = new Snap({ source: this.locationSource })
        this.modify = new Modify({ source: this.locationSource })

        this.map.addInteraction(this.snap)
        this.map.addInteraction(this.modify)

        // Add property feature if it is not present
        if (!this.propertyFeature) {
          const point = new Point(fromLonLat([this.longitude, this.latitude]))
          this.propertyFeature = new Feature({
            geometry: point
          })

          this.locationSource.addFeature(this.propertyFeature)
        }
      }
    },
    render () {
      if (this.rendered) {
        return
      } else {
        this.rendered = true
      }
      setTimeout(() => {
        this.osmLayer = new TileLayer({
          source: new XYZ({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          })
        })

        this.locationSource = new VectorSource()
        this.locationLayer = new VectorLayer({
          source: this.locationSource,
          style: new Style({
            fill: new Fill({
              color: 'rgba(255,46,67,0.47)'
            }),
            stroke: new Stroke({
              color: '#ffcc33',
              width: 2
            }),
            image: new CircleStyle({
              radius: 10,
              fill: new Fill({
                color: '#ff002c'
              })
            })
          })
        })

        this.grbSource = new VectorSource({
          format: new GeoJSON(),
          url: function (extent) {
            return 'https://geo.api.vlaanderen.be/GRB/wfs?' +
              'version=1.1.0&request=GetFeature&typename=GRB:ADP&' +
              'outputFormat=application/json&srsname=EPSG:3857&' +
              'bbox=' + extent.join(',') + ',EPSG:3857'
          },
          strategy: bboxStrategy
        })

        this.grbLayer = new VectorLayer({
          source: this.grbSource,
          style: (feature) => {
            const capakey = feature.getProperties().CAPAKEY
            if (capakey in this.selectedParcels) {
              return this.selectedParcelStyle
            } else {
              return this.defaultParcelStyle
            }
          }
        })

        this.map = new Map({
          target: 'map',
          layers: [
            this.osmLayer,
            this.grbLayer,
            this.locationLayer
          ],
          view: new View({
            center: fromLonLat([this.longitude, this.latitude]),
            zoom: 18
          })
        })

        this.grbLayerHidden = false

        this.map.on('moveend', (event) => {
          if (this.mode !== 'PARCEL') {
            return
          }
          var newZoomLevel = this.map.getView().getZoom()
          if (newZoomLevel < 18) {
            this.map.removeLayer(this.grbLayer)
            this.grbLayerHidden = true
          } else {
            if (this.grbLayerHidden) {
              console.debug('adding layer')
              this.map.addLayer(this.grbLayer)
              this.grbLayerHidden = false
            }
          }
        })

        this.activateLocationSelection()
        this.activateParcelSelection()
      }, 500)
    }
  }
}
</script>

<style scoped>
@import url(~ol/ol.css);

.toolbar {
  display: flex;
  align-items: flex-end;
}

.toolbar * {
  margin-right: 5px;
}

#map {
  margin-top: 5px;
}
</style>
